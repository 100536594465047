import { Injectable } from '@angular/core';
import { CountlyService } from '../../../../../../helpers-v1';

@Injectable()
export class CountlyEvents {
  constructor(
    private countlyService: CountlyService
  ) {}

  public onChangeForAnalytics(eventName, optionId) {
    const event = this.createEvent(eventName, optionId );
    this.countlyService.addBulkVariables(event);
  }

  /**
   * Creating event for analytics to track time range options clicks
   * @param eventName event name
   * @param optionId option which has been selected
   */
    private createEvent(eventName: string, optionId: string) {
      const country = sessionStorage.getItem('country');
      const customerStr = sessionStorage.getItem('user_customer');
      const customer = customerStr && customerStr !== 'undefined' && customerStr !== 'null' ?
        JSON.parse(customerStr) : null;
      const event = {
        event: eventName,
        customerCode: customer &&  customer?.customerCode ? customer?.customerCode : null,
        customerName: customer && customer?.customerDesc ? customer?.customerDesc : null,
        country: country,
        optionId: optionId
      };
      return event;
    }
}
