import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CmxHttpClient } from './http-client.service';
import { DocumentVersion } from '../models/documentVersion';


@Injectable()
export class DocumentService {

  public touSigned: boolean;

  constructor(private cmxHttp: CmxHttpClient) { }

  getDocumentVersions(countryCode: string, documentGroup?: string) {
      const uri = `v6/ce/documents/versions/last?documentGroup=${documentGroup}&countryCode=${countryCode}`;
      return this.cmxHttp.get<DocumentVersion[]>(uri)
        .pipe(map((res) => res ? res['documentVersions'] : []));
  }

  getDocumentLanguage(documentCode: string, countryCode: string, isPublic = false) {
      const uri = `v6/ce/documents/versions/last?documentCode=${documentCode}&isPublic=${isPublic}`;
      return this.cmxHttp.post(uri, { CountryCode: countryCode})
        .pipe(map((res) => res ? res['documentLanguages'] : []));
  }

  getDocumentLanguageWithParameters(documentCode: string, countryCode: string, isPublic = false, parameters: any[]) {
      const uri = `v6/ce/documents/versions/last?documentCode=${documentCode}&isPublic=${isPublic}`;
      return this.cmxHttp.post(uri, { CountryCode: countryCode, documentData: parameters})
        .pipe(map((res) => res ? res['documentLanguages'] : []));
  }

  signDocument(dataVersion: string, documentVersionId: number) {
    return this.cmxHttp.post('v6/secm/users/documents/signatures', { dataVersion, documentVersionId });
  }

  replaceLineBreaks(text: string) {
    const userCountryCode = sessionStorage.getItem('country') || 'US';
    if (['mx', 'gb'].includes(userCountryCode.toLowerCase())) {
      return text.replace(/(\r\n|\n)/g, '<br>');
    }
    return text;
  }

  updateTermsOfUse(isSigned: boolean): void {
    this.touSigned = isSigned;
  }

  get isTermsOfUseSigned(): boolean {
    const touSigned = this.touSigned || (/true/i).test(sessionStorage.getItem('touSigned')) || false;
    return touSigned;
  }

  getCountries(): any {
    const uri = `v4/gm/countries`;
    return this.cmxHttp.get(uri).pipe(map((res) => res['countries'] ? res['countries'] : []));
  }

  addLinkHelper(text: string): string
  {
    if(text.includes('href'))
      return '<a href="javascript:void(0)"></a>'+text;
    return text;
  }
}
