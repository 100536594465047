import { ISortHeader, ISortHeaders } from "../models/sortHeader";
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class SortingService {

    constructor() { }

    public orderCollection(header: ISortHeader, collection: any[], sorterCustomFn: any): any[] {
        if (!header.order || !header.property) {
            return collection;
        }
        const propertyName = header.property;
        const order = header.order;
        const sortCollection: any[] = this.orderByWithNullsAtEnd(collection, propertyName, order, sorterCustomFn);

        return sortCollection;
    }

    private orderByWithNullsAtEnd(collection: any[], propertyName: string, order: string, sorterCustomFn: any) {
        const partition = _.partition(collection, (x) => !!_.get(x, propertyName, null));
        let orderType = [];
        orderType.push(order);
        if (sorterCustomFn) {
            return _.concat(_.orderBy(partition[0], [sorterCustomFn], orderType), partition[1]);
        }

        return _.concat(_.orderBy(partition[0], propertyName, orderType), partition[1]);
      }
}
