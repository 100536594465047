import {
  FeatureTogglePropertyTypeDefinition,
  FeatureToggleType,
  IFeatureToggleConfig,
} from './app/feature-toggle-service/v1/src/feature-toggle-types';
export enum AppFeatures {
    UseChatbot = 'useChatbot',
    UseChatbotv2 = 'useChatbotv2',
    UseMarketingBanner = 'useMarketingBanner',
    UseIntegrationChat = 'useIntegrationChat',
    UseAnalyticsService = 'useAnalyticsService',
    UseAnalyticsOptInOptOut = 'useAnalyticsOptInOptOut',
    UseOneClickOrder = 'useOneClickOrder',
    UseUserBulkEdition = 'useUserBulkEdition',
    UseUserProfileV2 = 'useUserProfileV2',
    UseNotificationsSettings = 'useNotificationsSettings',
    UseProfileFunctions = 'useProfileFunctions',
    UseRemoveJobsite = 'useRemoveJobsite',
    UseMarketingOptInOptOut = 'useMarketingOptInOptOut',
    UseUserMgmtConsoleV2 = 'useUserMgmtConsoleV2',
    UseRegionalZones = 'useUseRegionalZones',
    UseDAEdition = 'useDAEdition',
    UseUserMaintenance = 'useUserMaintenance',
    UseProfileFunctionsComponent = 'useProfileFunctionsComponent',
    UseLinkCreditRequest = 'useLinkCreditRequest',
    CoBrowse = 'coBrowse',
    UseUserManagementReport = 'useUserManagementReport',
    UseDashboardV2 = 'useDashboardV2',
    CanDisplaySignUpCard = 'canDisplaySignUpCard',
    UseNotificationSettingsPreferences = 'useNotificationSettingsPreferences',
    UseProfileFunctionsV2 = 'useProfileFunctionsV2',
    UseSubscriptionsByChannel = 'useSubscriptionsByChannel'
}

export const appFeaturesConnectConfig = {
  name: 'CXGOS5',
  syncInterval: 0,
  version: '2.2.7.0',
};

export const appFeaturesConfig: IFeatureToggleConfig = {
  features: [
    {
      name: AppFeatures.UseChatbot,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseChatbotv2,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseIntegrationChat,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
        { name: 'role', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseMarketingBanner,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseAnalyticsService,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseOneClickOrder,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseAnalyticsOptInOptOut,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'user', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseUserBulkEdition,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseUserProfileV2,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseNotificationsSettings,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseProfileFunctions,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseRemoveJobsite,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseMarketingOptInOptOut,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseUserMgmtConsoleV2,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseRegionalZones,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseDAEdition,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseUserMaintenance,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseProfileFunctionsComponent,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseLinkCreditRequest,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.CoBrowse,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseUserManagementReport,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
        },
        {
            name: AppFeatures.UseDashboardV2,
            type: FeatureToggleType.Flag,
            defaultValue: false,
            properties: [
                { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
            ],
    },
    {
      name: AppFeatures.CanDisplaySignUpCard,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
        { name: 'state', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseNotificationSettingsPreferences,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
          { name: 'country', type: FeatureTogglePropertyTypeDefinition.String }
      ],
    },
    {
      name: AppFeatures.UseProfileFunctionsV2,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
    {
      name: AppFeatures.UseSubscriptionsByChannel,
      type: FeatureToggleType.Flag,
      defaultValue: false,
      properties: [
        { name: 'country', type: FeatureTogglePropertyTypeDefinition.String },
      ],
    },
  ]
};
